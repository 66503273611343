import { hideMenuItems, resetSitemap } from './sitemap'
import authenticationStore from './authenticationStore'

import * as Roles from './Roles'
export { Roles }

const initStore = (initialize, stores) =>
  authenticationStore({
    hideMenuItems,
    resetSitemap,
    initialize,
    stores
  })

export default initStore

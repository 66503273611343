export async function wait(timeInMillis = 1000) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, timeInMillis)
  })
}

export function getEmailDomain(email) {
  return email.substring(email.lastIndexOf('@') + 1)
}

export function getURLSearchParams() {
  const urlSearchParams = new URLSearchParams(document.location.search)
  return urlSearchParams.toString()
}

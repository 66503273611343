import { Auth0Client } from '@auth0/auth0-spa-js'
import { paths } from 'Navigation'
import { SingletonFetcher } from 'services/common/singletonFetcher'
import { getEmailDomain } from 'services/common'
import appConstants from 'appConstants'

class AuthService {
  /**
   * @type {Auth0Client}
   */
  #authClient = null

  /**
   * @type {SingletonFetcher}
   */
  #dataFetcher = null

  constructor() {
    this.#dataFetcher = new SingletonFetcher('/api/readAuthClient')
  }

  /**
   *
   * @returns {Promise<Auth0Client>}
   */
  async getAuthClient() {
    if (!this.#authClient) {
      await this._constructAuthClient()
    }

    return this.#authClient
  }

  async login(searchParams) {
    const authClient = await authService.getAuthClient()

    authClient.loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${window.location.origin}/callback?${searchParams}`
      }
    })
  }

  async logout(errorCode = null, email = '') {
    const authClient = await this.getAuthClient()
    const federated = this._getFederated(email)

    // returnTo parameter should be doubly uri encoded for query params to reflect correctly on redirection from auth0
    const returnTo = encodeURIComponent(this._getReturnToUrl(errorCode))

    if (federated) {
      const subTenantLogoutUrl = encodeURIComponent(
        `https://${authClient.domain}/v2/logout?client_id=${authClient.clientId}&returnTo=${returnTo}`
      )
      const auth0SLOUrl = `https://${authClient.tenant}/v2/logout?federated=${federated}&returnTo=${subTenantLogoutUrl}`
      this._redirectToLogoutUrl(auth0SLOUrl)
      return
    }

    await authClient.logout({
      logoutParams: {
        federated,
        returnTo
      }
    })
  }

  _getFederated(email) {
    const trimmedEmail = email?.trim().toLowerCase()

    if (!trimmedEmail) {
      return true
    }

    const domain = getEmailDomain(email)
    const idpList = this.#authClient.idpList
    const matchingIDP = idpList.find((idp) => idp.name === domain)

    return matchingIDP?.federated ?? true
  }

  /**
   * @private
   * @param {*} url
   */
  _redirectToLogoutUrl(url) {
    window.location.href = url
  }

  /**
   * @private
   */
  async _constructAuthClient() {
    const { domain, clientId, tenant, idp } = await this.#dataFetcher.fetch()

    this.#authClient = new Auth0Client({
      domain,
      clientId,
      useRefreshTokens: true,
      authorizationParams: {
        redirect_uri: window.location.origin + paths.Callback
      }
    })

    this.#authClient.domain = domain
    this.#authClient.clientId = clientId
    this.#authClient.tenant = tenant
    this.#authClient.idpList = idp
  }

  /**
   * @private
   */
  _getReturnToUrl(errorCode) {
    const currentSearchParams = new URLSearchParams(window.location.search)
    const newUrl = new URL(window.location.origin)
    const newSearchParams = new URLSearchParams()

    if (currentSearchParams.get('continue')) {
      newSearchParams.set('continue', currentSearchParams.get('continue'))
    }

    if (errorCode) {
      newSearchParams.set('signout', errorCode)
    }

    window.sessionStorage.setItem(appConstants.LOGOUT_SESSION_QUERY_PARAMS, newSearchParams.toString())

    newUrl.pathname = paths.Login

    return newUrl.toString()
  }
}

const authService = new AuthService()
export default authService

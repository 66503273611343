// app routes
export const Admin = '/admin'

export const WithHeader = '/(admin|select-instance|profile)'
export const Home = '/'

// public routes
export const Login = '/login'
export const Logout = '/logout'
export const AcceptLegalDocuments = '/accept-privacy-policy-and-terms-of-service'
export const Callback = '/callback'
export const PrivacyPolicy = '#privacy-policy'
export const TermsOfService = '#terms-of-service'
export const ReadmeOSS = '#readme-oss'

// private routes
export const Forbidden = '/forbidden'
export const ActivateInvitation = '/activateInvitation'
export const SelectInstance = '/select-instance'
export const Profile = '/profile'

export const AdminStaffGAS = `${Admin}/gas-users/:id?`
export const AdminStaffGASUpdate = `${Admin}/gas-users/:id`

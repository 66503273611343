import AdminStore, { Field } from '@humanics/he-react-common/lib/admin/stores/adminStore'
import fluxStore from '@humanics/he-react-common/lib/stores/fluxStore'

const fields = [
  Field('userId', 'user id'),
  Field('firstName', 'name'),
  Field('createdAt', 'role'),
  Field('email', 'email'),
  Field('homeUnitName', 'home unit'),
  Field('updatedAt', 'updated'),
  Field('isActive', 'status')
]

export default function UserProfilesStore() {
  const actions = fluxStore({
    loadUserProfiles,
    destroyUser,
    updateUserProfile,
    loadUserPermissions,
    revokeFacilityUserPermissions
  })

  const store = AdminStore({
    fields,
    userAdmin: true,
    entityName: 'UserProfile',
    api: {
      index: 'indexUserProfiles',
      update: 'updateUserProfile'
    },
    header: {
      title: 'User Profiles'
    },
    search: {
      field: 'firstName, lastName, email, phoneNumber',
      placeholder: 'Search for user profiles'
    }
  })

  async function loadUserProfiles(state, parameters) {
    const api = await store.getApi()
    const { stateKey } = await store.getAdminInfo()
    const userProfiles = await api.indexUserProfiles(parameters)
    return state.setIn([ stateKey, 'list' ], userProfiles)
  }

  async function destroyUser(state, userId) {
    const api = await store.getApi()
    const { stateKey } = await store.getAdminInfo()
    await api.destroyUser({ userId })
    return state.updateIn([ stateKey, 'list' ], userProfiles =>
      userProfiles.filter(userProfile => userProfile.userId !== userId)
    )
  }

  async function updateUserProfile(state, props) {
    const api = await store.getApi()
    const { stateKey } = await store.getAdminInfo()

    const { userId, firstName, lastName, email, phoneNumber, utcOffset, locale, timeZone } = props

    const userProfile = {
      firstName,
      lastName,
      email,
      phoneNumber,
      utcOffset,
      locale,
      timeZone
    }

    const updatedUserProfile = await api.updateUserProfile({
      userId,
      userProfile
    })
    return state.updateIn([ stateKey, 'list' ], userProfiles =>
      userProfiles.map(userProfileData => {
        if (userProfileData.userId === userId) {
          const { permissions } = userProfileData
          return { ...updatedUserProfile, permissions }
        } else {
          return userProfileData
        }
      })
    )
  }

  async function loadUserPermissions(state, accountUserId) {
    const api = await store.getApi()
    const { stateKey } = await store.getAdminInfo()
    const permissions = await api.indexPermissions({ accountUserId })
    return state.updateIn([ stateKey, 'list' ], userProfiles =>
      userProfiles.map(userProfile =>
        userProfile.userId === accountUserId ? { ...userProfile, permissions } : userProfile
      )
    )
  }

  async function revokeFacilityUserPermissions(state, userId) {
    const api = await store.getApi()
    const { stateKey } = await store.getAdminInfo()
    await api.revokeFacilityUserPermissions({ userId })
    return state.updateIn([ stateKey, 'list' ], userProfiles =>
      userProfiles.map(userProfile =>
        userProfile.userId === userId ? { ...userProfile, permissions: [] } : userProfile
      )
    )
  }

  return {
    ...store,
    ...actions
  }
}

import codes from 'errorCodeConstants'
import { UserAdministrator, UserSupportAdministrator } from './Roles'
import CustomCodeError from 'services/ErrorHandler/CustomCodeError'

function acceptFacilityInvitation({ instanceUri, invitationAuthorizationCode }) {
  const url = `/api/acceptInvitation?instanceUri=${window.btoa(instanceUri)}`

  return fetch(url, {
    body: JSON.stringify({ invitationAuthorizationCode }),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  }).then((xhr) => {
    const contentType = xhr.headers.get('content-type') || ''
    if (!contentType.includes('application/json')) {
      if (!xhr.ok) {
        throw new CustomCodeError(xhr.statusText, xhr.status)
      } else {
        return xhr
      }
    }
    return xhr.json().then((response) => {
      if (!xhr.ok) {
        throw response
      } else {
        return response
      }
    })
  })
}

function handleUnauthorizedError(error, actions) {
  const isUnauthorized = error.status === 401
  const isWrongCredsError = error.code === codes.LOGIN_ERROR
  const path = error.path || error.obj?.path
  if (!path) {
    return
  }
  if (isUnauthorized && path[0] !== 'deleteMySession' && !isWrongCredsError) {
    actions.logOut(error)
  }
}

function handleKnownErrors(error) {
  const handledError = error.body || error

  if (handledError.message || handledError.code) {
    throw handledError
  }
}

function isLocalOrigin(instanceUris, instanceLocation) {
  return (
    instanceUris.includes(instanceLocation.origin) ||
    instanceLocation.origin === 'http://local.instance.pontevecchio.hcvpc.io:1080'
  )
}

function isAdmin(userRole) {
  return userRole === UserAdministrator || userRole === UserSupportAdministrator
}

export { acceptFacilityInvitation, handleUnauthorizedError, handleKnownErrors, isLocalOrigin, isAdmin }

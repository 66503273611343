import { Component } from 'react'
import { withAppContext } from 'AppContext'
import { paths } from 'Navigation'
import history from 'browserHistory'
import authService from 'services/auth/authService'
import { getURLSearchParams } from 'services/common'

export class LogOut extends Component {
  componentDidCatch(error, info) {
    console.error('Error in logout:', error, info)
    this._replaceURLToLoginURL()
  }

  async componentDidMount() {
    const locationState = this.props.location?.state || {}

    if (locationState.forceLogout) {
      // clear the force logout state.
      history.replace({ pathname: paths.Logout, search: getURLSearchParams() })

      //Try logout irrespective of whether user is logged in or not.
      this._logoutUser()
      return
    }

    const { appState } = this.props
    const isLoggedIn = appState?.getIn([ 'authentication', 'isLoggedIn' ])

    if (!isLoggedIn) {
      this._replaceURLToLoginURL()
      return
    }

    this._logoutUser()
  }

  async _logoutUser() {
    const { appState, authStore } = this.props

    await authStore.logOut()

    const profile = appState?.getIn([ 'authentication', 'profile' ])
    const email = profile?.get('email')

    await authService.logout(null, email)
  }

  _replaceURLToLoginURL() {
    history.replace({ pathname: paths.Login, search: getURLSearchParams() })
  }

  render() {
    return <sh-spinner overlay label="Signing out..."></sh-spinner>
  }
}

export default withAppContext(LogOut)

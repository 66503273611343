import history from '../browserHistory'
import { generatePath, matchPath } from 'react-router-dom'
import * as paths from './paths'

const PATHS_KEYS = Object.keys(paths)

let instanceUri = null

function navigate(path, params = {}, followLink = true) {
  let url = generatePath(path, params)

  if (instanceUri) {
    url += '?' + navigate.getInstanceUriSearch()
  }

  if (followLink) {
    history.push(url, { from: history.location })
  }

  return url
}

function navigateFromTo(fromPath, toPath, params, followLink) {
  const { pathname } = history.location
  const { params: fromParams = {} } = matchPath(pathname, fromPath)
  return navigate(toPath, { ...fromParams, ...params }, followLink)
}

navigate.to = PATHS_KEYS.reduce((memo, key) => {
  const path = paths[key]
  memo[key] = (...params) => navigate(path, ...params)
  return memo
}, {})

navigate.from = PATHS_KEYS.reduce((memoFrom, keyFrom) => {
  const pathFrom = paths[keyFrom]
  const to = PATHS_KEYS.reduce((memoTo, keyTo) => {
    const pathTo = paths[keyTo]
    memoTo[keyTo] = (...params) => navigateFromTo(pathFrom, pathTo, ...params)
    return memoTo
  }, {})
  memoFrom[keyFrom] = { to }
  return memoFrom
}, {})

navigate.isPath = PATHS_KEYS.reduce((memo, key) => {
  const path = paths[key]
  memo[key] = (pathname, options = {}) => matchPath(pathname, { path, ...options }) !== null
  return memo
}, {})

navigate.clearHash = () => {
  history.location.hash = null
  history.push(history.location)
}

navigate.updateInstanceUri = newInstanceUri => {
  instanceUri = newInstanceUri
}

navigate.getInstanceUri = () => instanceUri

navigate.getInstanceUriSearch = searchParams => {
  const sp = new URLSearchParams(searchParams)
  if (instanceUri) {
    sp.set('continue', instanceUri)
  }
  return sp.toString()
}

navigate.modifyTo = to => {
  let _to
  if (to && typeof to !== 'string') {
    _to = to
    to = to.pathname
  }
  const isHash = to.startsWith('#')
  let sourceLocation = document.location
  if (!isHash) {
    sourceLocation = `${document.location.origin}${to}`
  }
  const location = new URL(sourceLocation)
  if (isHash) {
    location.hash = to
  }
  location.search = navigate.getInstanceUriSearch()
  let newTo = location.toString().replace(location.origin, '')
  if (_to) {
    _to.pathname = location.pathname
    _to.search = location.search
    newTo = _to
  }
  return newTo
}

export default navigate

import appConstants from 'appConstants'
import TermsOfServiceData from 'common/TermsOfServiceData'
import { navigate } from 'Navigation'
import { createRef, PureComponent } from 'react'

export class TermsOfService extends PureComponent {
  onClose(e) {
    e.preventDefault()
    navigate.clearHash()
  }

  constructor(props) {
    super(props)
    this.modalRef = createRef()
  }

  componentDidMount() {
    const modalElement = this.modalRef.current
    if (modalElement) {
      modalElement.addEventListener(appConstants.SHUI_EVENTS.MODAL_CLOSED, this.onClose)
    }
  }

  render() {
    return (
      <sh-modal
        visible={true}
        ref={this.modalRef}
        label={'CrewPlace Enterprise Terms Of Service'}
        modal-height="calc(100% - 160px)"
        modal-width="800px"
      >
        <TermsOfServiceData />
      </sh-modal>
    )
  }
}

export default TermsOfService

import { PureComponent } from 'react'
import { Admin, Header, Search } from '@humanics/he-react-common/lib/admin/components'
import DataController from './DataController'
import GasUsersAccordion from './GasUsersAccordion/GasUsersAccordion'
import GasUserUpdate from './GasUserUpdate'
import PropTypes from 'prop-types'
import { matchPath } from 'react-router-dom'
import { Map } from 'immutable'
import { withAppContext } from 'AppContext'

export class GasUsersAdmin extends PureComponent {
  static propTypes = {
    appState: PropTypes.instanceOf(Map).isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    navigateToParent: PropTypes.func.isRequired,
    navigateToChild: PropTypes.func.isRequired,
    updatePath: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)

    this.dataController = new DataController(this)
    this.state = { query: '', apiInitialized: false }
  }

  componentDidMount() {
    (async() => {
      await this.dataController.userProfilesStore.getApi()
      this.dataController.loadData()
      this.setState({ apiInitialized: true })
    })()
  }

  render() {
    if (!this.state.apiInitialized) {
      return null
    }

    const { userProfiles } = this.dataController

    return (
      <Admin>
        {this.renderHeader()}
        {this.renderSearch(userProfiles)}
        {this.renderGASUsersAccordion(userProfiles)}

        {this.renderUpdateGasProfile()}
      </Admin>
    )
  }

  renderUpdateGasProfile = () => {
    const { updatePath, location } = this.props
    const match = matchPath(location.pathname, { path: updatePath })
    if (!match) {
      return null
    }
    const { id: userId } = match.params
    if (!userId) {
      return null
    }

    const { userProfiles, userProfilesStore } = this.dataController
    const userProfile = userProfiles.find(i => i.userId === userId)

    const gasUserUpdateProps = {
      userProfile,
      match,
      updateUserProfile: userProfilesStore.updateUserProfile,
      closeModal: this.closeModal
    }

    return <GasUserUpdate {...gasUserUpdateProps} />
  }

  renderGASUsersAccordion(userProfiles) {
    const { appState, navigateToChild } = this.props
    const authUserId = appState.getIn([ 'authentication', 'facilityUser', 'userId' ])
    const { userProfilesStore, idns } = this.dataController

    const gasUsersAccordionAccordionProps = {
      userProfiles,
      idns,
      userProfilesStore,
      navigateToChild,
      authUserId
    }

    return <GasUsersAccordion {...gasUsersAccordionAccordionProps} />
  }

  renderSearch(userProfiles) {
    const { search, header } = this.dataController.adminInfo

    return (
      <div className="p2 bg-white pb0">
        <Search
          placeholder={search?.placeholder}
          onSearch={query => this.setState({ query })}
          onClearSearch={() => this.setState({ query: '' })}
          itemsFound={userProfiles}
          entityName={header.title}
        />
      </div>
    )
  }

  renderHeader() {
    const { header } = this.dataController.adminInfo
    return <Header title={header.title} actions={null} />
  }

  closeModal = () => this.props.navigateToParent()
}

export default withAppContext(GasUsersAdmin)

import { Component } from 'react'
import { pick } from 'lodash'
import GasUserData from './GasUserData'
import PropTypes from 'prop-types'

export default class GasUsersAccordion extends Component {
  static propTypes = {
    userProfiles: PropTypes.array.isRequired,
    idns: PropTypes.array,
    userProfilesStore: PropTypes.object.isRequired,
    navigateToChild: PropTypes.func.isRequired
  }

  render() {
    const { userProfiles } = this.props

    return (
      <div className="p2 bg-white">
        {this.renderHeader()}
        <div className="hx-facility-users-list">
          {userProfiles.map(userProfile => this.renderUserProfile(userProfile))}
        </div>
      </div>
    )
  }

  renderHeader() {
    return (
      <div className="hx-facility-users-list-header row bb1">
        <div className="upper small bold col-2 pt10 pb10 pl15 gray muted">Name</div>
        <div className="upper small bold col-2 pt10 pb10 pl0 gray muted">Email</div>
        <div className="upper small bold col-2 pt10 pb10 pl0 gray muted">Phone Number</div>
        <div className="upper small bold col-2 pt10 pb10 pl0 gray muted">Time Zone</div>
        <div className="upper small bold col-2 pt10 pb10 pl0 gray muted">UTC Offset</div>
      </div>
    )
  }

  renderUserProfile(userProfile) {
    const { userId } = userProfile
    const key = userId
    const gasUserDataProps = pick(
      {
        ...this.props,
        userProfile
      },
      [ 'idns', 'userProfile', 'userProfilesStore', 'authUserId', 'navigateToChild' ]
    )

    return <GasUserData {...gasUserDataProps} key={key} />
  }
}

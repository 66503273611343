import fluxStore, { initialState, onError } from '@humanics/he-react-common/lib/stores/fluxStore'
import { fromJS, List, Map } from 'immutable'
import { navigate } from 'Navigation'
import { readInvitationAuthorizationCode } from './Queries'
import { acceptFacilityInvitation, handleUnauthorizedError, handleKnownErrors, isLocalOrigin, isAdmin } from './utils'
import CustomSilentError from 'services/ErrorHandler/CustomSilentError'

const EMPTY_STATE = Map({
  isLoggedIn: false,
  profile: Map({}),
  sitemap: List([])
})

let _hideMenuItems
let _resetSitemap
let _initializeStores
let _gasApiUri
let _actions

initialState('authentication', EMPTY_STATE)

function _mapAuth({ userProfile, instances = [], invitations = [] }) {
  return Map({
    isLoggedIn: true,
    profile: Map(userProfile),
    instances: fromJS(instances),
    invitations: fromJS(invitations),
    sitemap: List([])
  })
}
function _initStores(state) {
  return Promise.resolve(state)
    .then(_actions.updateState(_hideMenuItems))
    .then(newState => _initializeStores(newState, { gasApiUri: _gasApiUri }))
}

function _subscribeForUnauthorizedError() {
  // This handle should process requests when auth.isLoggedIn equals true:
  onError(error => {
    // TODO fix the error to have always the same format
    // TODO logout with broken auth token? Redirect to login w/o logging out?
    handleUnauthorizedError(error, _actions)
  })
}

function _autoLogin(gas) {
  return gas
    .autoLogin({ displayError: false })
    .then(_mapAuth)
    .then(auth => initialState('authentication', auth, true))
    .then(_initStores)
    .then(_subscribeForUnauthorizedError)
    .catch(() => {
      // This is intentional
    }) // it was not logged in? don't worry
}

function _logOut(state, gas) {
  return gas
    .logOut()
    .then(() => state.set('authentication', EMPTY_STATE))
    .then(_resetSitemap)
}

async function _catchPseudoError(state) {
  handleKnownErrors(state)
  return _initStores(state)
}

function _redirectToInstanceUrl(instanceUri, reloadToken) {
  let redirectionUri = instanceUri.replace(/\/$/, '')

  if (reloadToken) {
    redirectionUri += '/#reloadToken'
  }

  global.document.location.assign(redirectionUri)
  console.info('success redirect to', redirectionUri)
}

function _isRedirectAllowed(checkPendingInvitations, invitations, instances) {
  if (checkPendingInvitations && invitations?.size) {
    console.info('User had an invitation')
    return false
  }

  if (!instances?.size) {
    console.info('No instances available')
    return false
  }

  return true
}
function _redirectToInstance(state, reloadToken = true, checkPendingInvitations = true) {
  const instances = state.getIn([ 'authentication', 'instances' ])
  const invitations = state.getIn([ 'authentication', 'invitations' ])

  if (!_isRedirectAllowed(checkPendingInvitations, invitations, instances)) {
    _subscribeForUnauthorizedError()
    throw state
  }

  const instanceUri = navigate.getInstanceUri()
  const userRole = state.getIn([ 'authentication', 'profile', 'roleId' ])

  if (isAdmin(userRole)) {
    console.info('user has access to users list')
    _subscribeForUnauthorizedError()
    throw state // skip and render list
  }

  // else - instance redirection logic
  const instanceUris = instances.map(i => i.get('uri'))

  if (instanceUri) {
    const instanceLocation = new URL(instanceUri)

    if (isLocalOrigin(instanceUris, instanceLocation)) {
      _redirectToInstanceUrl(instanceUri, reloadToken)
      return state
    }

    // else skip and render list
    console.info('instanceUri provided, but is not valid')
  }

  if (instanceUris.size === 1) {
    // implicit redirection
    const firstInstanceUri = instanceUris.first()
    _redirectToInstanceUrl(firstInstanceUri, reloadToken)
    return state
  }

  console.info('no instanceUri provided, there are 2+ instances available')
  _subscribeForUnauthorizedError() // move invocation to different place?
  throw state
}

function _createSession(state, parameters, gas) {
  return gas
    .logOut({ displayError: false }) //to delete present user session in same browser (whether present or not)
    .catch(_e => {
      //Do nothing if log out fails
    })
    .then(() => gas.login(parameters))
    .then(_mapAuth)
    .then(auth => state.set('authentication', auth))
    .then(newState => {
      return Promise.resolve(_redirectToInstance(newState)).then(() => {
        throw new CustomSilentError('redirecting', true)
      })
    })
    .catch(_catchPseudoError)
}
function _joinFacility(state, invitation, gas) {
  const invitationToken = invitation.get('invitationToken')
  const facilityUrlId = invitation.get('facilityUrlId')
  let instanceUri

  return gas.gqlClient
    .query(readInvitationAuthorizationCode, { invitationToken })
    .then(({ readInvitationAuthorizationCode: value }) => ({ ...value }))
    .then(params => {
      // Redirect user to the facility they just joined.
      instanceUri = params.instanceUri + `/${facilityUrlId}`
      return params
    })
    .then(acceptFacilityInvitation)
    .then(() => navigate.updateInstanceUri(instanceUri))
    .then(() => {
      const instanceUrl = new URL(instanceUri)
      return state.updateIn([ 'authentication', 'instances' ], instances => {
        return instances.push(Map({ uri: instanceUrl.origin }))
      })
    })
    .then(updatedState => _redirectToInstance(updatedState, true, false))
    .then(() => {
      throw new CustomSilentError('redirecting', true)
    })
    .catch(finalUpdatedState => {
      handleKnownErrors(finalUpdatedState)
      return finalUpdatedState
    })
}

async function _safeRedirectToInstance(state, reloadToken = true, checkPendingInvitations = true) {
  try {
    _redirectToInstance(state, reloadToken, checkPendingInvitations)
  } catch (e) {
    await _catchPseudoError(e)
    navigate.to.Home()
  }
}

export default function authenticationStore({ hideMenuItems, resetSitemap, initialize: initializeStores, stores }) {
  return async function({ gas, gasApiUri }) {
    initialState('context', Map({ gqlClient: gas.gqlClient }))
    const actions = fluxStore({
      logOut,
      createSession,
      joinFacility,
      updateState
    })
    _hideMenuItems = hideMenuItems
    _resetSitemap = resetSitemap
    _initializeStores = initializeStores
    _gasApiUri = gasApiUri
    _actions = actions

    await autoLogin()

    return {
      authStore: {
        ...actions,
        redirectToInstance: _redirectToInstance,
        safeRedirectToInstance: _safeRedirectToInstance,
        subscribeForUnauthorizedError: _subscribeForUnauthorizedError,
        acceptLatestLegalDocuments,
        autoLogin
      },
      ...stores
    }

    function autoLogin() {
      return _autoLogin(gas)
    }

    function updateState(state, cb) {
      return cb(state)
    }

    function logOut(state) {
      return _logOut(state, gas)
    }

    function createSession(state, parameters) {
      return _createSession(state, parameters, gas)
    }

    function joinFacility(state, invitation) {
      return _joinFacility(state, invitation, gas)
    }

    function acceptLatestLegalDocuments() {
      return gas.acceptLatestLegalDocuments()
    }
  }
}

import { withAppContext } from 'AppContext'
import { t } from 'i18n'
import { Component } from 'react'
import { navigate, paths } from 'Navigation'
import authService from 'services/auth/authService'
import codes from 'errorCodeConstants'
import history from 'browserHistory'

class Callback extends Component {
  async _authenticateUser() {
    try {
      const authClient = await authService.getAuthClient()
      await authClient.handleRedirectCallback()
      const idToken = await authClient.getIdTokenClaims()
      this._loginUser({ token: idToken.__raw })
    } catch (e) {
      console.error('Error authenticating user:', e)
      authService.logout()
    }
  }

  _loginUser(parameters) {
    const { authStore } = this.props

    authStore.createSession(parameters).then(({ error }) => {
      // Dont do anything for latest legal documents not accepted as that is handled by Global Accounts Service
      if (
        error?.message === 'redirecting' ||
        error?.graphQLErrors?.some(err => err.code === codes.LATEST_LEGAL_DOCUMENTS_NOT_ACCEPTED)
      ) {
        return
      }

      if (error) {
        console.error('There was an error while creating session', error)
        this._handleLoginError(error)
        return
      }

      // Remove code and state from url.
      const urlSearchParams = new URLSearchParams(document.location.search)
      urlSearchParams.delete('code')
      urlSearchParams.delete('state')

      history.replace({ pathname: paths.Home, search: urlSearchParams.toString() })
    })
  }

  _handleLoginError(error) {
    let errorCode = null

    if (error?.graphQLErrors?.some(err => err.code === codes.LOGIN_ERROR)) {
      errorCode = codes.USER_NOT_AUTHORIZED
    }

    if (error?.graphQLErrors?.some(err => err.code === codes.USER_NOT_ACTIVATED)) {
      errorCode = codes.USER_NOT_ACTIVATED
    }

    authService.logout(errorCode)
  }

  render() {
    return (
      <sh-spinner
        id="signInLoader"
        data-testid="signInLoader"
        overlay="true"
        label={t('auth.sign_in_text')}
      ></sh-spinner>
    )
  }

  componentDidMount() {
    // If user is logged in, then redirect him to his logged in page
    const { appState, authStore } = this.props
    const isLoggedIn = appState.getIn([ 'authentication', 'isLoggedIn' ])

    if (isLoggedIn) {
      authStore.safeRedirectToInstance(appState, false)
      return
    }

    // There should be a code and state query params
    const searchParams = new URLSearchParams(document.location.search)
    const code = searchParams.get('code')
    const state = searchParams.get('state')

    if (!code || !state) {
      navigate.from.Callback.to.Login()
      return
    }

    this._authenticateUser()
  }
}

export default withAppContext(Callback)

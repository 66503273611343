import { createSession_m,
  deleteMySession_m,
  readAuthorizationCode,
  readMyUserProfile,
  acceptTermsAndPolicy_m } from '../authentication/Queries'
import GQLClient from '@humanics/he-react-common/lib/stores/humanics/graphQL'
import { handleError } from 'services/ErrorHandler'
import history from 'browserHistory'
import { paths } from 'Navigation'
import codes from 'errorCodeConstants'

export default class GlobalAccountsService {
  constructor(uri) {
    this.uri = uri
  }

  initialize() {
    this.gqlClient = GQLClient.buildClient(this.uri, null, null, handleError)
  }

  withSession = (fn) => {
    return (...params) =>
      fn(...params).then(({ userProfile, ...error }) => {
        if (!userProfile) {
          throw error
        }

        // TODO move to external method
        return this.gqlClient
          .query(readAuthorizationCode)
          .then(({ readAuthorizationCode: { instances, invitations } }) => ({
            userProfile,
            instances,
            invitations
          }))
          .catch((gqlError) => {
            if (gqlError?.graphQLErrors?.some((err) => codes.LATEST_LEGAL_DOCUMENTS_NOT_ACCEPTED === err.code)) {
              const currentLocation = history.location
              const urlSearchParams = new URLSearchParams(document.location.search)
              urlSearchParams.delete('code')
              urlSearchParams.delete('state')

              // logout takes precedence over this error. (This is an edge case)
              if (currentLocation.pathname === paths.Logout) {
                history.replace({
                  pathname: currentLocation.pathname,
                  state: { forceLogout: true },
                  search: urlSearchParams.toString()
                })
              } else {
                history.replace({
                  pathname: paths.AcceptLegalDocuments,
                  state: { shouldAcceptLatestLegalDocuments: true },
                  search: urlSearchParams.toString()
                })
              }
            }

            throw gqlError
          })
      })
  }

  login = this.withSession((parameters) => {
    return this.gqlClient
      .mutate(createSession_m, { parameters }, { displayError: false })
      .then(({ createSession: data }) => data)
  })

  autoLogin = this.withSession((options) => {
    return this.gqlClient
      .query(readMyUserProfile, undefined, options)
      .then(({ readMyUserProfile: userProfile }) => ({ userProfile }))
  })

  logOut = (options) => {
    return this.gqlClient.mutate(deleteMySession_m, undefined, options)
  }

  acceptLatestLegalDocuments = () => {
    return this.gqlClient.mutate(acceptTermsAndPolicy_m)
  }
}

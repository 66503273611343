const appConstants = {
  SHUI_EVENTS: {
    VALUE_CHANGED: 'value-changed',
    MODAL_CLOSED: 'modal-closed'
  },

  CUSTOM_EVENTS: {
    BLUR: 'customBlur'
  },

  EVENTS: {
    MOUSE_UP: 'mouseup',
    KEYDOWN: 'keydown',
    FOCUS: 'focus'
  },

  KEYS: {
    TAB: 'Tab',
    ENTER: 'Enter'
  },

  LOGOUT_SESSION_QUERY_PARAMS: 'logoutSessionQueryParams'
}

export default appConstants

import { Redirect } from 'Navigation'
import { withAppContext } from 'AppContext'

export function DefaultRedirect(props) {
  const { appState } = props
  const sitemap = appState.get('sitemap')
  const url = getFirstMapItemUrl(sitemap)
  // TODO instanceUri parameter after redirecting to gas users admin view?

  if (!url) {
    return null
  }
  return <Redirect to={url} />
}

const getFirstMapItemUrl = sitemap => {
  const visibleItems = sitemap.filter(item => !item.get('hide'))
  const firstItem = visibleItems.get(0)

  if (firstItem) {
    const nestedItems = firstItem.get('items')

    if (!!nestedItems && nestedItems.size > 0) {
      const firstNestedItem = nestedItems.get(0)

      return firstNestedItem.get('url')
    }

    return firstItem.get('url')
  }
}

export default withAppContext(DefaultRedirect)

const codeConstants = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  SERVER_ERROR: 500,

  NO_AUTH_TOKEN: 'COM-3',
  AUTH_TOKEN_EXPIRED: 'COM-4',
  BAD_AUTH_TOKEN: 'COM-5',
  AUTH_TOKEN_BLACKLISTED: 'COM-6',
  AUTH_ACCESS_DENIED: 'COM-7',

  USER_NOT_AUTHORIZED: 'FOR-2',

  LOGIN_ERROR: 'ACC-2',
  REGISTRATION_TOKEN_USED: 'ACC-11',
  USER_ACTIVATION_TOKEN_USED: 'ACC-17',
  USER_ACTIVATED: 'ACC-18',
  RESET_LINK_EXPIRED: 'ACC-21',
  USER_NOT_ACTIVATED: 'ACC-29',
  USER_ALREADY_REGISTERED_WITH_THIS_TOKEN: 'ACC-35',

  INVITATION_REVOKED: 'PER-24',
  INVITATION_HAS_EXPIRED: 'PER-25',

  LATEST_LEGAL_DOCUMENTS_NOT_ACCEPTED: 'ACC-40',
  OPERATION_NOT_ALLOWED: 'ACC-42'
}

export default codeConstants

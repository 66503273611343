import { fromJS, List } from 'immutable'
import { initialState } from '@humanics/he-react-common/lib/stores/fluxStore'
import { navigate, paths } from 'Navigation'
import { Admins, All } from './Roles'

const menu = (title, url, roles = [], items = List(), hide = true) => ({
  title,
  url,
  roles,
  items,
  hide
})
const sitemap = fromJS([
  // menu('Login', paths.Login),
  menu('Profile', paths.Profile, All),
  menu('GAS Accounts', navigate.to.AdminStaffGAS(null, false), Admins)
])

initialState('sitemap', sitemap)

function hideMenuItems(state) {
  const userRoleId = state.getIn([ 'authentication', 'profile', 'roleId' ])
  const updateMenuItems = menuItem => menuItem.update('items', updateItems)
  const updateItems = items => {
    return items.map(item => item.set('hide', !isAuthorized(item.get('roles'))))
  }
  const isAuthorized = roles => roles.includes(userRoleId)
  const updateMenu = menuItem => {
    const items = menuItem.get('items')
    const roles = menuItem.get('roles')
    const hide =
      items.size === 0 ? !isAuthorized(roles) : items.count(item => !item.get('hide')) === 0
    return menuItem.set('hide', hide)
  }

  return state.update('sitemap', sitemapList => sitemapList.map(updateMenuItems).map(updateMenu))
}

function resetSitemap(state) {
  return state.set('sitemap', sitemap)
}

export { hideMenuItems, resetSitemap }

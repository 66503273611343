const form = {
  firstName: {
    definition: {
      label: 'First Name',
      required: true,
      type: 'string',
      withLabel: true
    },
    field: 'firstName',
    formItemClasses: 'w100',
    entityType: 'UserProfiles',
    path: userProfile => {
      const { firstName } = { ...userProfile }
      return firstName || ''
    }
  },
  lastName: {
    definition: {
      label: 'Last Name',
      required: true,
      type: 'string',
      withLabel: true
    },
    field: 'lastName',
    formItemClasses: 'w100',
    entityType: 'UserProfiles',
    path: userProfile => {
      const { lastName } = { ...userProfile }
      return lastName || ''
    }
  },
  email: {
    definition: {
      label: 'email',
      required: false,
      type: 'string',
      withLabel: true
    },
    field: 'email',
    formItemClasses: 'w100',
    entityType: 'UserProfiles',
    path: userProfile => {
      const { email } = { ...userProfile }
      return email || ''
    }
  },
  phoneNumber: {
    definition: {
      label: 'Phone Number',
      required: false,
      type: 'string',
      withLabel: true
    },
    field: 'phoneNumber',
    formItemClasses: 'w100',
    entityType: 'UserProfiles',
    path: userProfile => {
      const { phoneNumber } = { ...userProfile }
      return phoneNumber || ''
    }
  }
}

export default form

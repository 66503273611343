import { Component } from 'react'
import { Route } from 'react-router-dom'
import { withAppContext } from 'AppContext'
import { navigate, paths, Redirect } from 'Navigation'

export class PrivateRoute extends Component {
  render() {
    const { component: RouteComponent, componentProps = {}, roles = [], appState, ...rest } = this.props

    const auth = appState.get('authentication')
    const userRole = appState.getIn([ 'authentication', 'profile', 'roleId' ])
    const isAuthorized = roles.length === 0 || roles.includes(userRole)

    return (
      <Route
        {...rest}
        render={props => {
          const { params } = props.match || {}
          const pathToLogin = navigate.to.Login(params, false)
          if (!auth.get('isLoggedIn')) {
            return <Redirect to={pathToLogin} />
          }

          if (!isAuthorized) {
            return <Redirect to={paths.Home} />
          }

          return (
            <RouteComponent
              {...props}
              {...componentProps}
              appState={appState} // TODO why is it not worked without it?
            />
          )
        }}
      />
    )
  }
}

export default withAppContext(PrivateRoute)

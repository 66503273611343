import { Component } from 'react'
import { Map } from 'immutable'
import './Avatar.scss'

let errCache = Map()

export const clearCache = () => errCache = Map()

const fallbackSrc = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'

export default class Avatar extends Component {
  static defaultProps = {
    size: 32,
    renderVisibleOnly: false,
    visible: true,
    avatarClass: 'hx-staff-avatar rounded',
    initialsClass: 'hx-staff-initials rounded'
  }

  state = {}

  render() {
    const { userId, profile, size, visible, renderVisibleOnly, initialsClass, avatarClass } = this.props
    const avatarUrl = profile.get('avatarUrl')
    const src = () => {
      if (renderVisibleOnly && !visible) {
        return fallbackSrc
      }
      return avatarUrl
    }

    return avatarUrl && !errCache.get(userId)
      ? this.showAvatar(src, size, avatarClass)
      : this.showInitials(profile, size, initialsClass)
  }

  onError = () => {
    const { userId } = this.props
    errCache = errCache.set(userId, true)
    this.setState({ errCache })
  }

  showAvatar = (src, size, avatarClass) =>
    <img className={`${avatarClass} image-${size}`} onError={this.onError} src={src()} alt="" />


  showInitials = (profile, size, initialsClass) => {
    const firstName = profile.get('firstName')
    const lastName = profile.get('lastName')

    const attributes = {
      className: `${initialsClass} image-${size}`,
      'data-name': `${firstName?.charAt(0)}${lastName?.charAt(0)}`.toUpperCase()
    }

    return <div {...attributes} />
  }
}

import { Component } from 'react'
import Avatar from 'components/Avatar'

export default class StaffProfileInfo extends Component {
  render() {
    const { profile } = this.props

    if (profile.size === 0) {
      return null
    }

    const firstName = profile.get('firstName')
    const lastName = profile.get('lastName')
    const email = profile.get('email')
    const phoneNumber = profile.get('phoneNumber')

    return (
      <div className="hx-staff-profile-info bg-white">
        <aside className="pt30">
          <Avatar profile={profile} />
        </aside>

        <div className="pt25 pb15">
          <div className="title bold">
            {firstName} {lastName}
          </div>

          <div className="row align-middle p10 ph0">
            <div className="col-6">
              <div className="giant">{email}</div>
              <div className="giant">{phoneNumber}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default class DataController {
  constructor(component) {
    this.component = component
  }

  get adminStores() {
    return this.component.props.adminStores
  }

  get appState() {
    return this.component.props.appState
  }

  get userProfilesStore() {
    return this.adminStores.get('userProfiles')
  }

  // get facilityStore() {
  //   return this.adminStores.get('facility');
  // }

  get adminInfo() {
    return this.userProfilesStore.getAdminInfo()
  }

  get idns() {
    return this.appState.getIn([ 'admin.Facility', 'list' ])
  }

  get userProfiles() {
    const { search } = this.adminInfo

    return search
      ? this.userProfilesStore.filter(this.appState, search.field, this.component.state.query)
      : this.appState.getIn([ 'admin.UserProfile', 'list' ])
  }

  loadData() {
    // TODO proper pagination
    this.userProfilesStore.loadUserProfiles({ limit: 5000 })
  }

  loadUserPermissions(accountUserId) {
    return this.userProfilesStore.loadUserPermissions(accountUserId)
  }

  destroyUser(userId) {
    return this.userProfilesStore.destroyUser(userId)
  }

  revokeFacilityUserPermissions(userId) {
    return this.userProfilesStore.revokeFacilityUserPermissions(userId)
  }

  updateUserProfile(props) {
    return this.userProfilesStore.updateUserProfile(props)
  }
}

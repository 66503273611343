import Polyglot from 'node-polyglot'
import auth from './auth.i18n.en.json'
import common from './common.i18n.en.json'

const polyglot = new Polyglot()
export default polyglot
export const extend = polyglot.extend.bind(polyglot)
export const t = polyglot.t.bind(polyglot)

extend(auth)
extend(common)

import PropTypes from 'prop-types'
import { Component } from 'react'
import GasUserRow from './GasUserRow'
import GasUserPermissions from './GasUserRow/GasUserPermissions'

export default class GasUserData extends Component {
  static propTypes = {
    idns: PropTypes.array,
    userProfile: PropTypes.object.isRequired,
    userProfilesStore: PropTypes.object.isRequired,
    navigateToChild: PropTypes.func.isRequired,
    authUserId: PropTypes.string.isRequired
  }

  state = { isUserPermissionsExpanded: false }

  render() {
    const { userProfile, idns, navigateToChild, authUserId } = this.props
    const { isUserPermissionsExpanded } = this.state

    const gasUserRowProps = {
      userProfile,
      isUserPermissionsExpanded,
      toggleUserPermissions: this.toggleUserPermissions,
      destroyUser: this.destroyUser,
      navigateToChild
    }

    const gasUserPermissionsProps = {
      userProfile,
      idns,
      revokeFacilityUserPermissions: this.revokeFacilityUserPermissions,
      authUserId
    }

    return (
      <section className="hx-facility-user-data bb1">
        <GasUserRow {...gasUserRowProps} />
        {isUserPermissionsExpanded && <GasUserPermissions {...gasUserPermissionsProps} />}
      </section>
    )
  }

  toggleUserPermissions = event => {
    event.stopPropagation()

    const { userProfile, userProfilesStore } = this.props
    const { isUserPermissionsExpanded } = this.state
    const { permissions, userId } = userProfile

    this.setState({
      isUserPermissionsExpanded: !isUserPermissionsExpanded
    })

    if (!permissions) {
      userProfilesStore.loadUserPermissions(userId)
    }
  }

  destroyUser = event => {
    event.stopPropagation()

    const { userProfile, userProfilesStore } = this.props
    const { userId } = userProfile

    if (global.confirm('Are you sure you wish to delete GAS account?')) {
      userProfilesStore.destroyUser(userId)
    }
  }

  revokeFacilityUserPermissions = e => {
    e.stopPropagation()

    const { userProfile, userProfilesStore } = this.props
    const { userId } = userProfile

    if (global.confirm('Are you sure you wish to revoke user permissions?')) {
      userProfilesStore.revokeFacilityUserPermissions(userId)
    }
  }
}

import { navigate } from './Navigation'
import { List } from 'immutable'

function handleInstanceUri(location) {
  const searchParams = new URLSearchParams(location.search)
  const instanceUri = searchParams.get('continue')

  if (instanceUri) {
    return window.atob(instanceUri)
  }
}

function getAccountsUri({ origin }) {
  return origin === 'http://localhost:1081' ? 'http://accounts.humanics.local:81' : origin
}

function handleApiRequests(navigator, location) {
  if (location.pathname.startsWith('/api')) {
    navigator.serviceWorker.ready.then((reg) => reg.unregister()).then(() => location.reload())
    return false
  }
  return true
}

function getAppContext(appRoot) {
  const { context } = appRoot.state
  const { appState } = context
  const current = appState.get('context')
  const authInstances = appState.getIn([ 'authentication', 'instances' ])
  const instances = (authInstances || List()).toJS()
  const currentContext = current?.toJS() || {}

  return {
    ...context,
    ...currentContext,
    instances,
    navigate
  }
}

export { handleInstanceUri, getAccountsUri, handleApiRequests, getAppContext }

import { addWarningBanner } from '@humanics/he-react-common/lib/stores/bannersStore'
import { addErrorMessage, addWarningMessage } from '@humanics/he-react-common/lib/stores/messages'
import codes from 'errorCodeConstants'
import { t } from 'i18n'

function showGenericError(error, options) {
  const showFeedbackForm = true
  addErrorMessage({ ...error, showFeedbackForm }, options)
}

function showWarningBanner(error, options) {
  addWarningBanner(error, options)
}

function showWarningMessage(error, options) {
  addWarningMessage(error, options)
}

const typedErrorsHandlers = {
  ServiceUnavailableError: showGenericError,
  InvalidParametersError: (error, options) => {
    error = { ...error }

    if (error.code === codes.RESET_LINK_EXPIRED) {
      error.title = t('auth.oops_link_expired')
      error.message = t('auth.new_link_sent')
    }

    showWarningBanner(error, options)
  },
  AccessDeniedError: showWarningBanner,
  LockedError: showWarningBanner,
  InvalidOutputError: showGenericError,
  ForbiddenError: (error, options) => {
    switch (error.code) {
      case codes.LATEST_LEGAL_DOCUMENTS_NOT_ACCEPTED: // Handled in GlobalAccountsService
      case codes.OPERATION_NOT_ALLOWED: // Handle in calling context
        break
      default:
        showGenericError(error, options)
        break
    }
  },

  AuthenticationError: (error, options) => {
    const errorCodes = [
      codes.NO_AUTH_TOKEN,
      codes.AUTH_TOKEN_EXPIRED,
      codes.BAD_AUTH_TOKEN,
      codes.AUTH_TOKEN_BLACKLISTED
    ]

    if (errorCodes.includes(error.code)) {
      const message = t('auth.expired_session')
      showWarningBanner({ ...error, message }, options)
    }
  },
  OperationError: (error, options) => {
    error = { ...error }
    switch (error.code) {
      case codes.LOGIN_ERROR: // This error should be displayed in form
        return
      case codes.INVITATION_REVOKED:
        error.title = t('auth.oops_link_expired')
        error.message = t('auth.request_new_invite')
        break
      default: {
        /* */
      }
    }
    showWarningMessage(error, options)
  },
  InvalidRequestError: showGenericError,
  ConflictError: (error, options) => {
    error = { ...error }

    // These errors are handled in registration component.
    if (codes.REGISTRATION_TOKEN_USED === error.code || codes.USER_ALREADY_REGISTERED_WITH_THIS_TOKEN === error.code) {
      return
    }

    if (codes.INVITATION_HAS_EXPIRED === error.code) {
      error.title = t('auth.oops_link_expired')
      error.message = t('auth.new_link_sent')
    }

    showWarningMessage(error, options)
  },
  ApplicationError: showGenericError
}

export default typedErrorsHandlers

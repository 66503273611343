import { Component } from 'react'
import { withAppContext } from 'AppContext'
import StaffProfileInfo from './StaffProfileInfo'
import FacilitiesList from './FacilitiesList'
import './Profile.scss'
import { navigate } from 'Navigation'

export class Profile extends Component {
  componentDidMount() {
    const { appState } = this.props
    const invitations = appState.getIn([ 'authentication', 'invitations' ])

    if (invitations?.size) {
      return navigate.from.Profile.to.ActivateInvitation()
    }
  }

  render() {
    const { appState } = this.props
    const instances = appState.getIn([ 'authentication', 'instances' ])
    const facilities = instances.reduce(
      // TODo move to store?
      (memo, instance) => {
        const lfacilities = instance.get('facilities').reduce((memoF, facility) => {
          facility = facility.set('instanceName', instance.get('name'))
          facility = facility.set('instanceUri', instance.get('uri'))
          memoF.push(facility)
          return memoF
        }, [])
        memo = [ ...memo, ...lfacilities ]
        return memo
      },
      []
    )

    const profile = appState.getIn([ 'authentication', 'profile' ])

    return (
      <div className="hx-staff-container">
        <br />
        <StaffProfileInfo profile={profile} />
        <br />
        <br />
        <h4 className="river-bad">Facilities</h4>
        <br />
        <FacilitiesList facilities={facilities} />
      </div>
    )
  }
}

export default withAppContext(Profile)

import { Component } from 'react'

export default class FacilitiesList extends Component {
  render() {
    const { facilities } = this.props

    return (
      <div className="hx-staff-info-list bg-white p10 regent-gray">
        {facilities.length === 0 &&
          <div className="huge river-bad p30">No facilities available.</div>
        }
        {facilities.map(facility => {
          return (
            <div className="hx-list-item" key={facility.get('id')} onClick={this.onClick(facility)}>
              <div className="bb1 p20 ml10 pl0">
                <div className="huge river-bad">{facility.get('name')}</div>
                <div className="regent-gray small">{facility.get('instanceName')}</div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  onClick = facility => () => {
    const uri = new URL(facility.get('instanceUri'))
    uri.pathname = `/${facility.get('urlId')}`
    document.location.assign(uri.href)
  }
}

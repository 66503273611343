import * as messagesStore from '@humanics/he-react-common/lib/stores/messages'
import * as bannersStore from '@humanics/he-react-common/lib/stores/bannersStore'

import StoresFactory from '@humanics/he-react-common/lib/admin/stores/storesFactory'
import userProfilesStore from 'Admin/stores/userProfilesStore'
import feedbackStore from '@humanics/he-react-common/lib/stores/feedbackStore'

const STORES = {
  adminStores: () => StoresFactory({ userProfilesStore }),
  feedbackStore
}

export default function Stores(inlineStores = {}) {
  const stores = Object.entries({ ...STORES, ...inlineStores }).reduce((memo, [ name, store ]) => {
    memo[name] = store()
    return memo
  }, {})

  return {
    initialize,
    messagesStore,
    bannersStore,
    ...stores
  }

  function initialize(state, context) {
    return Object.entries(stores).reduce(
      (newState, [ , store ]) => store.initialize(newState, context),
      state
    )
  }
}

import { Component } from 'react'
import classNames from 'classnames'
import { withAppContext } from 'AppContext'
import { navigate } from 'Navigation'
import codes from '../errorCodeConstants'
import { Map } from 'immutable'

export class ActivateInvitation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      invitation: Map()
    }
  }

  componentDidCatch(error, info) {
    console.error(error, info)
  }

  onSubmit = e => {
    e.preventDefault()
    const { authStore } = this.props

    this.setState({ isLoading: true })

    authStore.joinFacility(this.state.invitation).then(({ error }) => {
      if (!error) {
        return this.setState({ isLoading: false })
      }
      if (error.message === 'redirecting') {
        return
      }
      this.setState({ isLoading: false })

      if ([ codes.INVITATION_HAS_EXPIRED, codes.INVITATION_REVOKED ].includes(error.code)) {
        navigate.to.Login()
      }
    })
  }

  componentDidMount() {
    const { appState } = this.props
    const invitations = appState.getIn([ 'authentication', 'invitations' ])

    if (!invitations?.size) {
      navigate.from.ActivateInvitation.to.Profile()
      return
    }
    // TODO: Discuss with UX and get a nice way for handling multiple invitations
    this.setState({ invitation: invitations.get(0) })
  }

  render() {
    const { isLoading } = this.state

    return (
      <main className="hx-auth" id="new-styles">
        <div className="hx-auth-container hx-double-column-layout">
          <div className="logo" />
          <form className="hx-form" onSubmit={this.onSubmit}>
            <header>
              <h3>You have been invited to join {this.state.invitation.get('facilityName')} </h3>
            </header>
            <footer className="_footer right-aligned">
              <button
                type="submit"
                disabled={isLoading}
                className={classNames('button primary', { loading: isLoading })}
                onClick={this.onSubmit}
              >
                Accept
              </button>
            </footer>
          </form>
        </div>
      </main>
    )
  }
}

export default withAppContext(ActivateInvitation)

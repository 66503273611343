import { Component } from 'react'
import classNames from 'classnames'
import StringInputField from '@humanics/he-react-common/lib/components/Form/StringInputField'
import CustomPhoneNumberInput from '@humanics/he-react-common/lib/components/Form/CustomPhoneNumberInput'
import form from './formDefinitions'
import PropTypes from 'prop-types'

export default class UserProfileForm extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    updateUserProfile: PropTypes.func.isRequired,
    userProfile: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.state = {
      form,
      isLoading: false
    }
    this.onClickSubmit = this.onClickSubmit.bind(this)
  }

  componentDidMount() {
    this.updateForm()
  }

  componentDidUpdate(prevProps) {
    const { userProfile } = this.props
    const { userProfile: prevUserProfile } = prevProps
    const isStaffChanged = userProfile !== prevUserProfile

    if (isStaffChanged) {
      this.updateForm()
    }
  }

  updateForm() {
    const { userProfile } = this.props
    const { form: formData } = this.state

    if (userProfile) {
      Object.keys(formData).forEach(field => {
        if (formData[field].path) {
          formData[field].value = formData[field].path(userProfile)
        }
      })
    }

    this.setState({ form: formData })
  }

  setFieldValue = (e, field) => {
    const { form: formData } = this.state
    formData[field].value = e?.hasOwnProperty('target') ? e.target.value : ''
    this.setState({ form: formData })
  }

  isValidForm = () => {
    const { form: formData } = this.state
    return Object.keys(formData)
      .map(field => formData[field])
      .filter(field => field.definition.required)
      .every(field => field.value)
  }

  serializeForm = () => {
    const { form: formData } = this.state
    const { id: userId } = this.props.match.params
    const serializedForm = { userId }
    for (const key in formData) {
      serializedForm[key] = formData[key].value || ''
    }
    serializedForm.phoneNumber =
      serializedForm.phoneNumber === '' || serializedForm.phoneNumber === '+'
        ? null
        : serializedForm.phoneNumber
    serializedForm.email = serializedForm.email === '' ? null : serializedForm.email
    return serializedForm
  }

  onClickSubmit = e => {
    e.preventDefault()

    const isValidForm = this.isValidForm()

    if (!isValidForm) {
      return
    }

    const { updateUserProfile, closeModal } = this.props
    const data = this.serializeForm()
    this.setState({ isLoading: true })
    updateUserProfile(data).then(closeModal)
  }

  render() {
    const { closeModal } = this.props
    const { form: formData, isLoading } = this.state

    return (
      <form className={'form mb0'}>
        <div className="p2 pb0 row">
          <StringInputField
            {...formData.firstName}
            onChange={e => this.setFieldValue(e, 'firstName')}
          />
          <StringInputField {...formData.lastName} onChange={e => this.setFieldValue(e, 'lastName')} />
          <StringInputField {...formData.email} onChange={e => this.setFieldValue(e, 'email')} />
          <CustomPhoneNumberInput
            {...formData.phoneNumber}
            onChange={e => this.setFieldValue(e, 'phoneNumber')}
          />
        </div>
        <footer className="_footer">
          <button onClick={closeModal} className="button float-left p5 _cancel">
            Cancel
          </button>
          <button
            onClick={this.onClickSubmit}
            type="submit"
            disabled={isLoading}
            className={classNames('button primary float-right _submit', {
              loading: isLoading
            })}
          >
            Save
          </button>
        </footer>
      </form>
    )
  }
}

import PropTypes from 'prop-types'
import { Component } from 'react'

export default class GasUserPermissions extends Component {
  static propTypes = {
    idns: PropTypes.array.isRequired,
    revokeFacilityUserPermissions: PropTypes.func,
    userProfile: PropTypes.object.isRequired,
    authUserId: PropTypes.string.isRequired
  }

  render() {
    const { userProfile } = this.props
    const { permissions } = userProfile

    return (
      <div className="row">
        {this.renderUserFacilityHeader()}
        {permissions && permissions.length > 0 ?
          permissions.map((permission, index) => this.renderUserFacility(permission, index))
          :
          <div className="row col-12 bg-alabaster">
            <div className="pl25">There are no facility associated with this user</div>
          </div>
        }
      </div>
    )
  }

  renderUserFacilityHeader = () => {
    const { userProfile, revokeFacilityUserPermissions, authUserId } = this.props
    const { permissions, userId } = userProfile
    const hasPermissions = permissions && permissions.length > 0
    const isCurrentUser = authUserId === userId

    return (
      <div className="row col-12 bb1 bg-aluminum">
        <div className="small bold col-2 p10 pl25 muted">IDN Name</div>
        <div className="small bold col-2 p10 pl0 muted">IDN Location</div>
        <div className="small bold col-2 p10 pl0 muted">Facility Name</div>
        <div className="small bold col-2 p10 pl0 muted">State</div>
        <div className="small bold col-2 p10 pl0 muted">Location</div>
        <div className="col-2">
          <div className="row w70 p5 ph0">

            {/* TODO: check if we should have opportunity to Revoke Permissions of 'System Administrator' */}
            {!isCurrentUser && hasPermissions &&
              <span className="col-10">
                <span
                  className="button primary tiny danger w100 text-center"
                  onClick={revokeFacilityUserPermissions}
                >
                  Revoke Permissions
                </span>
              </span>
            }
          </div>
        </div>
      </div>
    )
  }

  renderUserFacility = (permission, index) => {
    const { idns } = this.props
    const { facilityId } = permission
    let facilitySearch = {}

    const idn = idns.find(i =>
      i.facilities.some(facility => {
        const search = facility.id === facilityId
        if (search) {
          facilitySearch = facility
        }
        return search
      })
    )

    if (!idn) {
      return null
    }

    const { name: idnName, location: idnLocation } = idn
    const { name, location, state } = facilitySearch

    return (
      <div key={index} className="row col-12 bg-alabaster">
        <div className="col-2 pl25">{idnName}</div>
        <div className="col-2">{idnLocation}</div>
        <div className="col-2">{name}</div>
        <div className="col-2">{state}</div>
        <div className="col-2">{location}</div>
      </div>
    )
  }
}

import 'core-js'
import { createRoot } from 'react-dom/client'
import '@shui/core/sh-button'
import '@shui/core/sh-input-text'
import '@shui/core/sh-page'
import '@shui/core/sh-spinner'
import '@shui/core/sh-splash-screen'
import '@shui/core/sh-text'
import '@shui/core/sh-grid'
import '@shui/core/sh-modal'
import '@shui/core/sh-checkbox'
import '@shui/core/sh-card'
import '@shui/core/sh-stepper'
import '@shui/core/sh-icon'
import './assets/web.scss'
import '@shui/core/styles/fonts.css'
import '@shui/core/shui/shui-styles.css'
import './assets/index.scss'
import Main from 'Main'
import { authenticationStore, GlobalAccountsService } from './stores'
import registerServiceWorker from 'registerServiceWorker'
import { navigate } from 'Navigation'
import { onError } from '@humanics/he-react-common/lib/stores/fluxStore'
import { handleError } from './services/ErrorHandler'
import { getAccountsUri,
  getAppContext,
  handleApiRequests,
  handleInstanceUri } from './indexService'

onError(handleError)

const { location } = document

const instanceUri = handleInstanceUri(location)
navigate.updateInstanceUri(instanceUri)
runApp()

async function runApp() {
  const uri = getAccountsUri(location)

  if (!handleApiRequests(navigator, location)) {
    return
  }

  const gas = new GlobalAccountsService(`${uri}/api/graphql/v1/master`)
  gas.initialize()

  const authStore = await authenticationStore({
    gas,
    gasApiUri: uri
  })

  const appRoot = createRoot(document.getElementById('root'))
  appRoot.render(<Main authStores={authStore} />)

  global.appContext = () => getAppContext(appRoot)

  registerServiceWorker()
}

import { Component } from 'react'
import ModalWithConfirmation from '@humanics/he-react-common/lib/admin/components/ModalWithConfirmation'
import UserProfileForm from './UserProfileForm'
import PropTypes from 'prop-types'

export default class GasUserUpdate extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { isWarningVisible: false, form: {} }
  }

  render() {
    const { closeModal } = this.props

    const modalProps = {
      modalTitle: 'Update Staff Profile',
      onClose: closeModal,
      isModalVisible: true
    }

    return (
      <ModalWithConfirmation {...modalProps}>
        <UserProfileForm {...this.props} />
      </ModalWithConfirmation>
    )
  }
}

import { Component } from 'react'
import { PrivateRoute } from '../auth'
import { Admins } from '../stores/authentication/Roles'
import GasUsersAdmin from './modules/GasUsersAdmin'
import { navigate, paths } from 'Navigation'

export default class AdminRoutes extends Component {
  render() {
    const { stores, appState } = this.props
    return (
      <>
        <PrivateRoute
          path={paths.AdminStaffGAS}
          componentProps={{
            appState,
            stores,
            updatePath: paths.AdminStaffGASUpdate,
            navigateToParent: navigate.to.AdminStaffGAS,
            navigateToChild: userId => navigate.to.AdminStaffGASUpdate({ id: userId })
          }}
          component={GasUsersAdmin}
          roles={Admins}
        />
      </>
    )
  }
}

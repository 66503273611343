import { addWarningBanner } from '@humanics/he-react-common/lib/stores/bannersStore'
import { withAppContext } from 'AppContext'
import appConstants from 'appConstants'
import history from 'browserHistory'
import codes from 'errorCodeConstants'
import { t } from 'i18n'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import { Component } from 'react'
import authService from 'services/auth/authService'
import constants from 'utils/constants'
import { useContext } from 'react'
import CustomerSupport, { CustomerSupportContext } from '@humanics/he-react-common/lib/components/CustomerSupport'

const { digitalServicesActLink } = constants

const SupportHelp = () => {
  const supportContext = useContext(CustomerSupportContext)

  return (
    <span className='external-link' style={{ color: '#5e6069' }} onClick={supportContext.handleClick}>{t('help_text')}</span>
  )
}

class Login extends Component {
  static propTypes = {
    authStore: PropTypes.object.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    appState: PropTypes.objectOf(Map).isRequired
  }

  componentDidMount() {
    const { appState, authStore } = this.props
    const isLoggedIn = appState.getIn([ 'authentication', 'isLoggedIn' ])

    if (isLoggedIn) {
      authStore.safeRedirectToInstance(appState, false)
      return
    }

    this._handleLogoutSessionQueryParams()
    this._checkForAutoRedirect(global.location.href)
    this._checkForErrorCode(global.location.href)
  }

  _handleLogoutSessionQueryParams() {
    const logoutSessionQueryParams = window.sessionStorage.getItem(appConstants.LOGOUT_SESSION_QUERY_PARAMS)

    if (!logoutSessionQueryParams) {
      return
    }

    const searchParams = new URLSearchParams(logoutSessionQueryParams)
    const currentSearchParams = new URLSearchParams(window.location.search)

    searchParams.forEach((value, key) => {
      currentSearchParams.set(key, value)
    })

    history.replace({ search: searchParams.toString() })
    window.sessionStorage.removeItem(appConstants.LOGOUT_SESSION_QUERY_PARAMS)
  }

  _checkForAutoRedirect(url) {
    const autoRedirect = new URL(url).searchParams.get('autoRedirect')
    if (autoRedirect === 'true') {
      this.onLoginButtonClick()
    }
  }

  _checkForErrorCode(url) {
    const errorCode = new URL(url).searchParams.get('signout')
    let message = void 0

    switch (errorCode) {
      case codes.NO_AUTH_TOKEN:
      case codes.AUTH_TOKEN_EXPIRED:
      case codes.BAD_AUTH_TOKEN:
      case codes.AUTH_TOKEN_BLACKLISTED:
      case codes.AUTH_ACCESS_DENIED: // TODO check 403 cases
        message = t('auth.expired_session')
        addWarningBanner({ message })
        break
      case codes.USER_NOT_ACTIVATED:
        message = t('auth.not_activated')
        this._showWarningBannerAndRemoveErrorCodeFromUrl(message)
        break
      case codes.USER_NOT_AUTHORIZED:
        message = t('auth.not_authorized')
        this._showWarningBannerAndRemoveErrorCodeFromUrl(message)
        break
      default:
        break
    }
  }

  _showWarningBannerAndRemoveErrorCodeFromUrl(message) {
    addWarningBanner({ message })

    const urlSearchParams = new URLSearchParams(document.location.search)
    urlSearchParams.delete('signout')

    history.replace({ search: urlSearchParams.toString() })
  }

  onLoginButtonClick = async() => {
    const searchParams = new URLSearchParams(document.location.search)
    authService.login(searchParams)
  }

  render() {
    const userAgent = window.navigator.userAgent.toLowerCase()

    if (userAgent.includes('android')) {
      const androidAppUrl = window.MOBILE_APP_ANDROID_URL
      if (androidAppUrl) {
        window.location.replace(androidAppUrl)
        return null
      }
    }

    if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
      const iosAppUrl = window.MOBILE_APP_IOS_URL
      if (iosAppUrl) {
        window.location.replace(iosAppUrl)
        return null
      }
    }

    return (
      <main className="hx-auth" id="new-styles">
        <sh-page>
          <sh-splash-screen
            style={{ background: 'none' }}
            label={t('product_name')}
            image="url('../SplashScreen_KeyVisual.jpg')"
          >
            <sh-button
              id="loginButton"
              data-testid="loginButton"
              size="l"
              label={t('login_button_text')}
              type="submit"
              onClick={() => this.onLoginButtonClick()}
            ></sh-button>
            <sh-text slot="info" size="body-2" color="secondary">
              <div>
                <span>
                  © {new Date().getFullYear()} {t('copyright_text')}
                </span>
                <span style={{ color: '#5e6069' }}> | </span>
                <span>
                  <a className="external-link" href={digitalServicesActLink} target="_blank" rel="noreferrer">
                    {t('digital_service_act_text')}
                  </a>
                </span>
                <CustomerSupport>
                  <>
                    <span style={{ color: '#5e6069' }}> | </span>
                    <SupportHelp />
                  </>
                </CustomerSupport>
              </div>
            </sh-text>
          </sh-splash-screen>
        </sh-page>
      </main>
    )
  }
}

export default withAppContext(Login)

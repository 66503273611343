import { PureComponent } from 'react'
import classNames from 'classnames'
import Menu from './Menu'
import { withAppContext } from 'AppContext'
import { Link, paths } from 'Navigation'
import Avatar from '../components/Avatar'
import { t } from 'i18n'
import constants from 'utils/constants'
import CustomerSupport, { CustomerSupportContext } from '@humanics/he-react-common/lib/components/CustomerSupport'
import { useContext } from 'react'

const { digitalServicesActLink } = constants

const SupportHelp = () => {
  const supportContext = useContext(CustomerSupportContext)

  return (
    <li onClick={supportContext.handleClick}>
      <button className="bold smaller upper text-left">{t('help_text')}</button>
    </li>
  )
}

class Header extends PureComponent {
  render() {
    const { appState } = this.props
    const auth = appState.get('authentication')
    const sitemap = appState.get('sitemap')
    const isLoggedIn = auth.get('isLoggedIn')

    const profile = auth.get('profile')

    const data = {
      isLoggedIn,
      firstName: profile?.get('firstName'),
      lastName: profile?.get('lastName'),
      email: profile?.get('email'),
      phoneNumber: profile?.get('phoneNumber')
    }

    return (
      <header id="header" data-component="Header">
        <header className="hx-header bg-riverbad">
          <aside className="float-left">
            <Link className="bg-charcoal" to={paths.Home}>
              <div className="image-32 hx-logo-orange" />
            </Link>
          </aside>

          <Menu sitemap={sitemap} />

          <aside className={classNames('_side-menu', { hide: !isLoggedIn })}>
            <button className="bg-charcoal hide">
              <i className="icon-bell" />
            </button>
            <div className="dropdown-menu">
              <button className="text-right">
                <Avatar profile={profile} />
                <i className="icon-down slate-gray" />
              </button>
              <ul className="bg-brightgray text-left">
                <li>
                  <Link to={paths.PrivacyPolicy} className="_privacy gull-gray bold smaller upper">
                    {t('privacy_policy_text')}
                  </Link>
                </li>
                <li>
                  <Link to={paths.TermsOfService} className="_termsOfService gull-gray bold smaller upper">
                    {t('terms_of_service_text')}
                  </Link>
                </li>
                <li>
                  <Link to={paths.ReadmeOSS} className="_readmeoss gull-gray bold smaller upper">
                    {t('readme_oss_text')}
                  </Link>
                </li>
                <li>
                  <a
                    className="_digitalServicesAct gull-gray bold smaller upper"
                    href={digitalServicesActLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('digital_service_act_text')}
                  </a>
                </li>
                <CustomerSupport data={data}>
                  <SupportHelp />
                </CustomerSupport>
                <li>
                  <Link to={paths.Logout} className="_logout gull-gray bold smaller upper">
                    {t('auth.logout')}
                  </Link>
                </li>
              </ul>
              <div />
            </div>
          </aside>
        </header>
      </header>
    )
  }
}

export default withAppContext(Header)

import { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export default class GasUserRow extends Component {
  static propTypes = {
    destroyUser: PropTypes.func.isRequired,
    isUserPermissionsExpanded: PropTypes.bool,
    toggleUserPermissions: PropTypes.func.isRequired,
    userProfile: PropTypes.object.isRequired,
    navigateToChild: PropTypes.func.isRequired
  }

  render() {
    const { userProfile,
      toggleUserPermissions,
      destroyUser,
      isUserPermissionsExpanded } = this.props
    const { userId, firstName, lastName, email, phoneNumber, timeZone, utcOffset } = userProfile

    const fullName = `${firstName} ${lastName}`

    const expanderClassName = classNames('icon _expanderIcon', {
      'icon-up': isUserPermissionsExpanded,
      'icon-down': !isUserPermissionsExpanded
    })

    const rowClassName = classNames('hx-facility-users-row row cursor-pointer', {
      'bg-antiflash-white': isUserPermissionsExpanded
    })

    return (
      <div className={rowClassName} onClick={this.openUpdateModal(userId)}>
        <div className="col-2 pb10 pt10">{fullName}</div>
        <div className="col-2 pb10 pt10 pl0">{email}</div>
        <div className="col-2 pb10 pt10 pl0">{phoneNumber}</div>
        <div className="col-2 pb10 pt10 pl0">{timeZone}</div>
        <div className="col-2 pb10 pt10 pl0">{utcOffset}</div>
        <div className="col-2 pb10 pt10 pl0">
          <div className="row w70">

            <span className="col-10">
              <sh-button color="primary" onClick={destroyUser} size="m" label="Destroys account" />
            </span>
          </div>
          <i className={expanderClassName} onClick={toggleUserPermissions} />
        </div>
      </div>
    )
  }

  openUpdateModal = userId => () => this.props.navigateToChild(userId)
}

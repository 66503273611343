import { Component } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import history from 'browserHistory'
import Header from 'Header'
import { getState, onChange } from '@humanics/he-react-common/lib/stores/fluxStore'
import { setOfflineMode } from '@humanics/he-react-common/lib/stores/bannersStore'
import { ActivateInvitation,
  DefaultRedirect,
  HashSwitch,
  Login,
  Logout,
  PrivateRoute,
  AcceptLegalDocuments,
  Callback } from 'auth'

import Profile from 'Profile'
import PrivacyPolicy from 'Header/PrivacyPolicy'
import TermsOfService from 'Header/TermsOfService'
import ReadmeOSS from 'Header/Readme.oss'
import { AppContext } from 'AppContext'
import Banner, { OfflineBannerStatus } from 'Banner'
import { paths } from 'Navigation'
import { Dialog, GlobalError } from 'Modal'

import { AdminRoutes } from 'Admin'
import 'auth/auth.scss'
import { t } from './i18n'
import ErrorBoundary from 'ErrorBoundary'
import typedErrorsHandlers from 'services/ErrorHandler/typedErrorsHandlers'

export default class Main extends Component {
  constructor(props) {
    super(props)
    const appState = getState()
    const { authStores } = props
    this.Dialog = Dialog(this, t)
    const gqlClient = (state) => state.getIn([ 'context', 'gqlClient' ])
    const context = {
      appState,
      gqlClient: gqlClient(appState),
      ...authStores
    }

    this.state = { context }
    this.offChange = onChange((newAppState) =>
      this.setState((prev) => ({
        isLoading: false,
        context: {
          ...prev.context,
          gqlClient: gqlClient(newAppState),
          appState: newAppState
        }
      }))
    )
  }

  componentWillUnmount() {
    this.offChange()
  }

  componentDidMount() {
    global.addEventListener('offline', (e) => {
      console.warn('App is offline', e)
      setOfflineMode({ message: t('auth.unable_to_connect_to_internet') })
    })
    document.title = t('product_name')
  }

  render() {
    return (
      <AppContext.Provider value={this.state.context}>
        <Router history={history}>
          <GlobalError t={t} />
          <ErrorBoundary onError={typedErrorsHandlers['ApplicationError']}>
            <Route path={paths.WithHeader} component={Header} />
            <Banner />
            <OfflineBannerStatus />
            <this.Dialog />
            <Switch>
              <Route path={paths.Login} component={Login} />
              <Route path={paths.AcceptLegalDocuments} component={AcceptLegalDocuments} />
              <Route path={paths.Callback} component={Callback} />
              <Route path={paths.Logout} component={Logout} />
              <PrivateRoute path={paths.ActivateInvitation} component={ActivateInvitation} />
              <PrivateRoute path={paths.Admin} component={AdminRoutes} />
              <PrivateRoute path={paths.Profile} exact component={Profile} />
              <PrivateRoute path="" exact component={DefaultRedirect} />
            </Switch>
            <HashSwitch
              hashMap={{
                [paths.TermsOfService]: TermsOfService,
                [paths.PrivacyPolicy]: PrivacyPolicy,
                [paths.ReadmeOSS]: ReadmeOSS
              }}
            />
          </ErrorBoundary>
        </Router>
      </AppContext.Provider>
    )
  }
}

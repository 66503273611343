import appConstants from 'appConstants'
import PrivacyPolicyData from 'common/PrivacyPolicyData'
import { navigate } from 'Navigation'
import { createRef, PureComponent } from 'react'

export class PrivacyPolicy extends PureComponent {
  onClose(e) {
    e.preventDefault()
    navigate.clearHash()
  }

  constructor(props) {
    super(props)
    this.modalRef = createRef()
  }

  componentDidMount() {
    const modalElement = this.modalRef.current
    if (modalElement) {
      modalElement.addEventListener(appConstants.SHUI_EVENTS.MODAL_CLOSED, this.onClose)
    }
  }

  render() {
    return (
      <sh-modal
        visible={true}
        ref={this.modalRef}
        label={'Privacy Notice for CrewPlace Enterprise'}
        modal-height="calc(100% - 160px)"
        modal-width="800px"
      >
        <PrivacyPolicyData />
      </sh-modal>
    )
  }
}

export default PrivacyPolicy

const { wait } = require('services/common')

export class SingletonFetcher {
  #data = null
  #isLoadedData = false
  #isFetchingData = false
  #url = null
  #retryCount = 0
  #retryAttempts = 0
  #waitTime = 0

  constructor(fetchUrl, retryAttempts = 5, waitTime = 100) {
    this.#url = fetchUrl
    this.#retryAttempts = retryAttempts
    this.#waitTime = waitTime
  }

  /**
   * Use to fetch the data from the `fetchUrl`.
   * Ensures that at any given time, only a single call is made to server.
   * Multiple calls will share the same response.
   *
   * To requery the server again, call `refetch`.
   * @returns A promise which resolves to the data expected on calling `fetchUrl`.
   */
  async fetch() {
    if (this.#retryCount >= this.#retryAttempts) {
      throw new Error(`Maximum retry attempts (${this.#retryAttempts}) exceeded for url: ${this.#url}`)
    }

    while (this.#isFetchingData) {
      await wait(this.#waitTime)
    }

    if (this.#isLoadedData) {
      return this.#data
    }

    this.#isLoadedData = false
    this.#isFetchingData = true

    try {
      const response = await fetch(this.#url)
      this.#data = await response.json()
      this.#isLoadedData = true
    } catch (e) {
      console.error(`Failed to get the data for url: ${this.#url}. Error:`, e)
    }

    this.#isFetchingData = false

    if (!this.#isLoadedData) {
      this.#retryCount++
      return this.fetch()
    }

    return this.#data
  }

  /**
   * Allows to requery the server for the `fetchUrl`.
   * @returns A promise which resolves to the data expected on calling `fetchUrl`.
   */
  async refetch() {
    this.#isLoadedData = false
    return this.fetch()
  }
}

import { Link as ReactLink } from 'react-router-dom'
import navigate from './navigate'

export default function Link({ to, children, ...rest }) {
  return (
    <ReactLink {...rest} to={navigate.modifyTo(to)}>
      {children}
    </ReactLink>
  )
}

import { withAppContext } from 'AppContext'
import { t } from 'i18n'
import { Component } from 'react'
import history from 'browserHistory'
import PrivacyPolicyData from 'common/PrivacyPolicyData'
import TermsOfServiceData from 'common/TermsOfServiceData'
import { addErrorBanner } from '@humanics/he-react-common/lib/stores/bannersStore'
import { paths } from 'Navigation'
import { getURLSearchParams } from 'services/common'
import constants from 'utils/constants'

const { digitalServicesActLink } = constants

class AcceptLegalDocuments extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeStepper: 1,
      isPrivacyPolicyAccepted: false,
      isTermsOfServiceAccepted: false,
      isSubmitting: false
    }
  }

  onNextClick = () => {
    this.setState({ activeStepper: this.state.activeStepper + 1 })
  }

  onPreviousClick = () => {
    this.setState({ activeStepper: this.state.activeStepper - 1 })
  }

  togglePrivacyPolicyAcceptanceCheckbox = () => {
    this.setState({ isPrivacyPolicyAccepted: !this.state.isPrivacyPolicyAccepted })
  }

  toggleTermsOfServiceAcceptanceCheckbox = () => {
    this.setState({ isTermsOfServiceAccepted: !this.state.isTermsOfServiceAccepted })
  }

  onSubmit = async() => {
    if (this.state.isSubmitting || !this.state.isPrivacyPolicyAccepted || !this.state.isTermsOfServiceAccepted) {
      return
    }

    this.setState({ isSubmitting: true })

    try {
      const { authStore } = this.props
      await authStore.acceptLatestLegalDocuments()
      await authStore.autoLogin()

      this.setState({ isSubmitting: false })

      const { appState } = this.props
      authStore.safeRedirectToInstance(appState)
    } catch (error) {
      console.error('Failed to accept privacy policy and terms of service', error)
      addErrorBanner({ message: t('latest_legal_documents_acceptance_failed_text') })
      this.setState({ isSubmitting: false })
    }
  }

  render() {
    return (
      <div style={{ width: '100%', height: 'calc(100% - 32px)', padding: '16px 0' }}>
        {this.state.isSubmitting && <sh-spinner overlay></sh-spinner>}
        <div style={{ width: '100%', maxWidth: '1000px', height: '100%', margin: '0 auto' }}>
          <sh-card
            data-testid="mainCard"
            style={{ maxWidth: '1200px', margin: 'auto' }}
            label={t('updated_legal_documents_header_text')}
          >
            <sh-stepper style={{ marginTop: '4px' }} value={this.state.activeStepper} slot="header" id="demo-stepper-2">
              <sh-stepper-item
                data-testid="privacyPolicyStepper"
                label={t('privacy_policy_text')}
                type={this.state.isPrivacyPolicyAccepted ? 'success' : ''}
              ></sh-stepper-item>
              <sh-stepper-item
                data-testid="termsOfServiceStepper"
                label={t('terms_of_service_text')}
                type={this.state.isTermsOfServiceAccepted ? 'success' : ''}
              ></sh-stepper-item>
            </sh-stepper>

            {this.state.activeStepper === 1 && <PrivacyPolicyData />}
            {this.state.activeStepper === 2 && <TermsOfServiceData />}

            {this.state.activeStepper === 1 &&
              <sh-checkbox
                id="ppAcceptCheckbox"
                data-testid="ppAcceptCheckbox"
                slot="footer"
                active={this.state.isPrivacyPolicyAccepted ? true : undefined}
                style={{ width: 'fit-content', marginRight: 'auto' }}
                label={t('privacy_policy_acceptance_text')}
                onClick={() => this.togglePrivacyPolicyAcceptanceCheckbox()}
              ></sh-checkbox>
            }

            {this.state.activeStepper === 2 &&
              <sh-wrapper slot="footer" style={{ width: 'fit-content', marginRight: 'auto', paddingTop: '10px' }}>
                <div class="information-body-container">
                  <sh-checkbox
                    id="tsAcceptCheckbox"
                    data-testid="tsAcceptCheckbox"
                    active={this.state.isTermsOfServiceAccepted ? true : undefined}
                    label={''}
                    className="information-checkbox"
                    onClick={() => this.toggleTermsOfServiceAcceptanceCheckbox()}
                  ></sh-checkbox>
                  <sh-text size="body-3" color="primary" className="information-body">
                    {t('terms_of_service_acceptance_text')}
                    {t('and')}
                    <a
                      href={digitalServicesActLink}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: 'underline' }}
                    >
                      {t('digital_service_act_text')}
                    </a>
                  </sh-text>
                </div>
              </sh-wrapper>
            }

            {this.state.activeStepper === 2 &&
              <sh-button
                id="previousButton"
                data-testid="previousButton"
                slot="footer"
                label={t('previous_button_text')}
                onClick={() => this.onPreviousClick()}
                color="secondary"
              ></sh-button>
            }

            {this.state.activeStepper === 1 &&
              <sh-button
                id="nextButton"
                data-testid="nextButton"
                slot="footer"
                label={t('next_button_text')}
                disabled={!this.state.isPrivacyPolicyAccepted ? true : undefined}
                onClick={() => this.onNextClick()}
              ></sh-button>
            }
            {this.state.activeStepper === 2 &&
              <sh-button
                id="proceedButton"
                data-testid="proceedButton"
                slot="footer"
                label={t('proceed_button_text')}
                disabled={!this.state.isTermsOfServiceAccepted ? true : undefined}
                onClick={() => this.onSubmit()}
              ></sh-button>
            }
          </sh-card>
        </div>
      </div>
    )
  }

  componentDidMount() {
    const locationState = this.props.location?.state || {}

    if (!locationState.shouldAcceptLatestLegalDocuments) {
      return history.replace({ pathname: paths.Login, search: getURLSearchParams() })
    }

    // Once it has been validated that call to this component was genuine,
    // now remove the state from here, so it cant be called again.
    history.replace({ pathname: paths.AcceptLegalDocuments, search: getURLSearchParams() })
  }
}

export default withAppContext(AcceptLegalDocuments)
